.react-tel-input .special-label {
    color: rgba(0, 0, 0, 0.38);
    transform: translate(14px, -6px) scale(0.75);
    z-index: 1;
    pointer-events: none;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transform-origin: top left;
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    padding: 0;
    font-size: 0.8rem;
    font-style: normal;
    font-family: Open sans, sans-serif;
    font-weight: 300;
    line-height: 1;
}

.react-tel-input .form-control:focus {
    border-color: #333;
    box-shadow: none;
}

.react-tel-input .form-control {
    font-size: 0.8rem;
    width: 100%;
}

.react-tel-input > div.flag-dropdown.open > ul {
    width: max-content;
}

.react-tel-input .country-list {
    width: 100%;
}
