.crop-control {
  display: flex;
  align-items: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.crop-control-zoom-button {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  padding: 0;
  font-style: normal;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
}
